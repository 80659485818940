<template>
<div class="sdg sdgg" style="width: 100%; min-height: 100vh; background-color: #f1f1f1; padding-bottom: 50px">
    <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd; z-index: 222" title="取款" left-arrow @click-left="$router.back()" />
    <div style="height: 46px"></div>
    <!-- 取款方式 -->
    <div class="pay-way-grid">
        <div v-for="(type, index) in visiblePayWayList" :key="index" class="pay-way-item" :class="{ active: activeTabIndex === index }" @click="onClickTab(index, type)">
            <div style="text-align: center;">
                <van-image v-if="type.payimg" :src="type.payimg" width="40px" height="40px" />
                <div class="pay-way-title">{{ type.content }}</div>
            </div>

        </div>
    </div>
    <!-- 如果有更多内容，则显示“展开更多” -->
    <span v-if="payWayList.length > 8" class="expand-label" @click="toggleExpand">
        {{ showAll ? '收起' : '展开更多' }}
    </span>

    <div class="usrse" style="padding-bottom: 20px;">
        <!-- Topay -->
        <!-- <div class="hgs" v-if="checkedType.content && checkedType.content.toLowerCase() === 'topay'"> -->
        <div class="hgs" v-if="checkedType.id && (checkedType.id === '1' || checkedType.id === 1)">
            <div class="nams">选择Topay地址</div>
            <!-- <div v-if="TopayList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addToPay')">+添加Topay地址</div> -->
            <div v-if="TopayList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addPayAddress/Topay/1')">+添加Topay地址</div>
            <div v-else>
                <div class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="openTopaySelect" v-if="!selectedTopay || !selectedTopay.bank_no">选择Topay地址</div>
                <div class="nams selected-address" v-if="selectedTopay && selectedTopay.bank_no">

                    <van-tag class="custom-tag" plain type="primary" closeable @close="removeTopayAddress">
                        <span class="bank-owner"> {{ selectedTopay.bank_owner }} :</span>
                        <span class="bank-address"> ****{{ selectedTopay.bank_no.substr(-4) }}</span>
                    </van-tag>

                </div>
                <div v-if="dialogTopaySelect" class="dialog-overlay">
                    <div class="dialog">
                        <div class="dialog-header">
                            <span>选择Topay地址</span>
                            <van-button @click="closeTopaySelect" size="mini" type="warning" class="btn">关闭</van-button>
                        </div>
                        <div class="dialog-body">
                            <ul class="dialog-list">
                                <li v-for="(item, index) in TopayList" :key="index" @click="selectTopayAddress(item)" class="dialog-item">
                                    <div class="dialog-item-content">
                                        <span class="bank-owner"> {{ item.bank_owner }} :</span>
                                        <span class="bank-address"> ****{{ item.bank_no.substr(-4) }}</span>
                                        <van-button type="danger" size="mini" @click.stop="deleteAddress(index, item.id, 'topay')" class="btn"> 删除</van-button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Okpay -->
        <!-- <div class="hgs" v-if="checkedType.content && checkedType.content.toLowerCase() === 'okpay'"> -->
        <div class="hgs" v-if="checkedType.id && (checkedType.id === '2' || checkedType.id === 2)">
            <div class="nams">选择Okpay地址</div>
            <!-- <div v-if="OkpayList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addOkPay')">+添加Okpay地址</div> -->
            <div v-if="OkpayList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addPayAddress/Okpay/2')">+添加Okpay地址</div>
            <div v-else>
                <div class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="openOkpaySelect" v-if="!selectedOkpay || !selectedOkpay.bank_no">选择Okpay地址</div>
                <div class="nams" v-if="selectedOkpay && selectedOkpay.bank_no">

                    <van-tag class="custom-tag" plain type="primary" closeable @close="removeOkpayAddress">
                        <span class="bank-owner"> {{ selectedOkpay.bank_owner }} :</span>
                        <span class="bank-address"> ****{{ selectedOkpay.bank_no.substr(-4) }}</span>
                    </van-tag>

                </div>
                <div v-if="dialogOkpaySelect" class="dialog-overlay">
                    <div class="dialog">
                        <div class="dialog-header">
                            <span>选择Okpay地址</span>
                            <van-button @click="closeOkpaySelect" size="mini" type="warning" class="btn">关闭</van-button>
                        </div>
                        <div class="dialog-body">
                            <ul class="dialog-list">
                                <li v-for="(item, index) in OkpayList" :key="index" @click="selectOkpayAddress(item)" class="dialog-item">
                                    <div class="dialog-item-content">
                                        <span class="bank-owner"> {{ item.bank_owner }} :</span>
                                        <span class="bank-address"> ****{{ item.bank_no.substr(-4) }}</span>
                                        <van-button type="danger" size="mini" @click.stop="deleteAddress(index, item.id, 'okpay')" class="btn"> 删除</van-button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div style="color: #597ef7; height: 30px; line-height: 30px; text-align: center">选择Okpay地址</div>
            </div>
        </div>
        <!-- Gopay -->
        <!-- <div class="hgs" v-if="checkedType.content && checkedType.content.toLowerCase() === 'gopay'"> -->
        <div class="hgs" v-if="checkedType.id && (checkedType.id === '8'  || checkedType.id === 8)">
            <div class="nams">选择Gopay地址</div>
            <!-- <div v-if="GopayList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addGopay')">+添加Gopay地址</div> -->
            <div v-if="GopayList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addPayAddress/Gopay/8')">+添加Gopay地址</div>
            <div v-else>
                <div class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="openGopaySelect" v-if="!selectedGopay || !selectedGopay.bank_no">选择Gopay地址</div>
                <div class="nams selected-address" v-if="selectedGopay && selectedGopay.bank_no">

                    <van-tag class="custom-tag" plain type="primary" closeable @close="removeGopayAddress">
                        <span class="bank-owner"> {{ selectedGopay.bank_owner }} :</span>
                        <span class="bank-address"> ****{{ selectedGopay.bank_no.substr(-4) }}</span>
                    </van-tag>

                </div>
                <div v-if="dialogGopaySelect" class="dialog-overlay">
                    <div class="dialog">
                        <div class="dialog-header">
                            <span>选择Gopay地址</span>
                            <van-button @click="closeGopaySelect" size="mini" type="warning" class="btn">关闭</van-button>
                        </div>
                        <div class="dialog-body">
                            <ul class="dialog-list">
                                <li v-for="(item, index) in GopayList" :key="index" @click="selectGopayAddress(item)" class="dialog-item">
                                    <div class="dialog-item-content">
                                        <span class="bank-owner"> {{ item.bank_owner }} :</span>
                                        <span class="bank-address"> ****{{ item.bank_no.substr(-4) }}</span>
                                        <van-button type="danger" size="mini" @click.stop="deleteAddress(index, item.id, 'gopay')" class="btn"> 删除</van-button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Alipay -->
        <!-- <div class="hgs" v-if="checkedType.content && checkedType.content.toLowerCase() === 'alipay'"> -->
        <div class="hgs" v-if="checkedType.id && (checkedType.id === '10'  || checkedType.id === 10)">
            <div class="nams">选择支付宝账号（Alipay地址）</div>
            <!-- <div v-if="AlipayList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addAlipay')">+添加Alipay地址</div> -->
            <div v-if="AlipayList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addPayAddress/Alipay/10')">+添加支付宝账号（Alipay地址）</div>
            <div v-else>
                <div class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="openAlipaySelect" v-if="!selectedAlipay || !selectedAlipay.bank_no">选择支付宝账号（Alipay地址）</div>
                <div class="nams selected-address" v-if="selectedAlipay && selectedAlipay.bank_no">

                    <van-tag class="custom-tag" plain type="primary" closeable @close="removeAlipayAddress">
                        <span class="bank-owner"> {{ selectedAlipay.bank_owner }} :</span>
                        <span class="bank-address"> ****{{ selectedAlipay.bank_no.substr(-4) }}</span>
                    </van-tag>

                </div>
                <div v-if="dialogAlipaySelect" class="dialog-overlay">
                    <div class="dialog">
                        <div class="dialog-header">
                            <span>选择支付宝账号（Alipay地址）</span>
                            <van-button @click="closeAlipaySelect" size="mini" type="warning" class="btn">关闭</van-button>
                        </div>
                        <div class="dialog-body">
                            <ul class="dialog-list">
                                <li v-for="(item, index) in AlipayList" :key="index" @click="selectAlipayAddress(item)" class="dialog-item">
                                    <div class="dialog-item-content">
                                        <span class="bank-owner"> {{ item.bank_owner }} :</span>
                                        <span class="bank-address"> ****{{ item.bank_no.substr(-4) }}</span>
                                        <van-button type="danger" size="mini" @click.stop="deleteAddress(index, item.id, 'alipay')" class="btn"> 删除</van-button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 银行卡 -->
        <!-- <div class="hgs" v-if="checkedType.content && checkedType.content.toLowerCase() === 'bank'"> -->
        <div class="hgs" v-if="checkedType.id && (checkedType.id === '11' || checkedType.id === 11)">
            <div class="nams">选择银行卡</div>
            <div v-if="BankList.length === 0" class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="$parent.goNav('/addBankCard')">+添加银行卡</div>
            <div v-else>
                <div class="nams" style="color: #597ef7; height: 30px; line-height: 30px; text-align: center" @click="openBankSelect" v-if="!selectedBank || !selectedBank.bank_no">选择银行卡</div>
                <div class="nams selected-address" v-if="selectedBank && selectedBank.bank_no">

                    <van-tag class="custom-tag" plain type="primary" closeable @close="removeBankAddress">
                        <span class="bank-owner"> {{ selectedBank.bank_owner }} - {{ selectedBank.bank }} :</span>
                        <span class="bank-address"> ****{{ selectedBank.bank_no.substr(-4) }}</span>
                    </van-tag>

                </div>
                <div v-if="dialogBankSelect" class="dialog-overlay">
                    <div class="dialog">
                        <div class="dialog-header">
                            <span style="display: flex;align-items: center;">选择银行卡<van-button @click="$parent.goNav('/addBankCard')" size="mini" type="primary" class="btn">+添加</van-button></span>

                            <van-button @click="closeBankSelect" size="mini" type="warning" class="btn">关闭</van-button>
                        </div>
                        <div class="dialog-body">
                            <ul class="dialog-list">
                                <li v-for="(item, index) in BankList" :key="index" @click="selectBankAddress(item)" class="dialog-item">
                                    <div class="dialog-item-content">
                                        <span class="bank-owner"> {{ item.bank_owner }} - {{ item.bank }} :</span>
                                        <span class="bank-address"> ****{{ item.bank_no.substr(-4) }}</span>
                                        <van-button type="danger" size="mini" @click.stop="deleteAddress(index, item.id, 'bank')" class="btn"> 删除</van-button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hgs">
            <div class="nams">取款金额</div>
            <div>
                <van-cell-group>
                    <van-field v-model="amount" type="text" :placeholder="amountPlaceholder" @input="handleInput2">
                        <template #button>
                            <van-button @click="bigMey($store.state.userInfo.balance)" size="mini" type="info">最大金额</van-button>
                        </template>
                    </van-field>
                </van-cell-group>
            </div>
        </div>

        <div class="hgs">
            <div class="nams">支付密码验证</div>
            <div>
                <van-cell-group>
                    <van-field v-model="password" autocomplete="new-password" type="password" placeholder="请输入支付密码"> </van-field>
                </van-cell-group>
            </div>
        </div>

        <div style="margin: 0 auto; width: 86%">
            <van-button type="info" style="margin-top: 20px; width: 100%" @click="withdraw(checkedType)" class="deposit-button">立即取款</van-button>
            <div class="textcns" style="text-align: center; color: #999; padding: 10px 0;font-size: medium;line-height: 1.2;">USDT提现？取款遇到问题？<br>联系 <span @click="$parent.openKefu" style="color: #597ef7; display: inline-block; margin: 0 6px">人工客服</span> 解决</div>
        </div>
    </div>

    <div v-if="activeTabIndex===0" style="background: #fff; box-sizing: border-box; padding: 0 20px">
        <div class="qibao">
            <div class="fes">钱包金额</div>
            <div class="imgs"><img @click="$parent.getUserInfoShowLoding()" src="/static/image/iconRefresh.5b108ae65439270527aeee8ac17c2aca.png" alt="" /></div>
            <div class="btns" @click="transall">一键回收</div>
        </div>
        <div class="mesg">
            <div class="bosgf">
                <div class="top">
                    <!-- <img src="/static/image/qianbao123.png" alt="" /> -->
                    中心钱包
                </div>
                <div class="bots"><span>￥</span>{{ $store.state.userInfo.balance }}</div>
            </div>
            <div class="bosgf">
                <div class="top">
                    <!-- <img src="/static/image/qianbao123.png" alt="" /> -->
                    游戏钱包
                </div>
                <div class="bots"><span>￥</span>{{ $store.state.userInfo.gameblance }}</div>
            </div>
        </div>

        <div class="gameBox" v-if="balancelist.length">
            <div class="lis" v-for="(item, index) in balancelist" v-if="index < showLis" :key="index">
                <div class="name">{{ item.name }}</div>
                <div class="num">{{ item.balance }}</div>
            </div>
            <div class="lis" v-if="showLis != 3" @click="changShowLis(3)">
                <div class="name">收起</div>
                <div class="num"><img src="/static/image/xiangshang.png" alt="" /></div>
            </div>
            <div class="lis" v-if="showLis == 3" @click="changShowLis(balancelist.length)">
                <div class="name">展开</div>
                <div class="num"><img src="/static/image/xiangxia.png" alt="" /></div>
            </div>
        </div>
    </div>

    <div v-if="iframeSrc" class="iframe-overlay">
        <div class="iframe-content">
            <button class="close-btn" @click="closeIframe">×</button>
            <iframe :src="iframeSrc" width="100%" height="100%" frameborder="0" allowfullscreen>
            </iframe>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'withdrawal',
    data() {
        return {

            AlipayList: [],
            dialogAlipaySelect: false,
            selectedAlipay: {},

            BankList: [],
            dialogBankSelect: false,
            selectedBank: {},

            GopayList: [],
            dialogGopaySelect: false,
            selectedGopay: {},

            TopayList: [],
            dialogTopaySelect: false,
            selectedTopay: {},

            OkpayList: [],
            dialogOkpaySelect: false,
            selectedOkpay: {},

            iframeSrc: null,
            payWayList: {},

            amount: null,
            password: null,
            daoTime: null,
            balancelist: [],
            showLis: 3,
            show: false,
            betAmount: null,

            checkedType: {},
            activeTabIndex: 0,

            showAll: false, // 控制是否显示所有项
            visiblePayWayList: [],

            amountPlaceholder: "请输入取款金额",
            payRange: [],
        };
    },
    created() {
        let that = this;
        that.getPayWay();
        that.getWithdrawal();
        that.getBetAmount();
        that.getbalancelist();
        that.daoTime = setInterval(() => {
            that.getbalancelistNoLoding();
        }, 3500);
    },
    watch: {
        // 监听路由变化
        '$route'(to, from) {
            // 路由变化时重新请求数据
            if (to.path === '/withdrawal') {
                this.activeTabIndex = 0
                this.getPayWay();
                this.getWithdrawal();
                this.getBetAmount();
                this.getbalancelist();
            }

        },
    },
    methods: {
        toggleExpand() {
            let that = this;
            that.showAll = !that.showAll; // 切换显示更多或收起
            that.visiblePayWayList = that.showAll ? that.payWayList : that.payWayList.slice(0, 8);
        },
        onClickTab(tab, type) {
            let that = this;
            that.activeTabIndex = tab;
            that.checkedType = type;

            const min_price = that.payWayList[tab].min_price || 100;
            const max_price = that.payWayList[tab].max_price || 10000;
            that.amountPlaceholder = "请输入取款金额, " + min_price + " ~ " + max_price
            that.payRange = [min_price, max_price]
        },
        handleInput2() {
            let value = this.amount;

            // 如果值小于 0，设置为 0
            if (parseFloat(value) < 0) {
                value = 0;
            }
            // 如果大于2位小数，则保留2位小数
            if (value.toString().indexOf('.') !== -1) {
                const decimalPlaces = value.toString().split('.')[1].length;
                if (decimalPlaces > 2) {
                    value = value.split('.')[0] + '.' + value.split('.')[1].slice(0, 2);
                    value = parseFloat(value);

                }
            }
            this.amount = value;
        },
        deleteAddress(index, id, type) {
            let that = this;
            that.$dialog.confirm({
                    title: '提示',
                    message: '确认删除?',
                })
                .then(() => {
                    that.$parent.showLoading();
                    that.$apiFun.post('/api/delcard', {
                        id: id
                    }).then(res => {
                        if (res.code == 200) {
                            that.$parent.showTost(1, "删除成功");
                        }
                        that.$parent.hideLoading();
                        if (type.toLowerCase() === 'okpay') {
                            that.OkpayList.splice(index, 1);
                        } else if (type.toLowerCase() === 'topay') {
                            that.TopayList.splice(index, 1);
                        } else if (type.toLowerCase() === 'gopay') {
                            that.GopayList.splice(index, 1);
                        } else if (type.toLowerCase() === 'bank') {
                            that.BankList.splice(index, 1);
                        } else if (type.toLowerCase() === 'alipay') {
                            that.AlipayList.splice(index, 1);
                        }

                    });
                })
                .catch((e) => {
                    console.log(e)
                });
        },

        openIframe(url) {
            try {
                console.log("openIframe", url)
                const protocol = window.location.protocol;
                console.log("协议：", protocol)
                if (url.startsWith(protocol)) {
                    this.$parent.goNav(`/rechargePage?backIndex=false&url=${url}`)
                } else {
                    window.location.href = url
                }
            } catch (error) {
                that.$parent.showTost(0, error.message);
                that.$parent.showTost(0, "暂时无法提供服务，请选择其他方式或金额");
            }

            // this.iframeSrc = url;
            // window.location.href = url
            // window.open(url, '_blank');
        },
        closeIframe() {
            this.iframeSrc = null;
        },

        //Alipay 地址选择弹出框
        openAlipaySelect() {
            this.dialogAlipaySelect = this.AlipayList.length > 0;
        },
        selectAlipayAddress(item) {
            this.selectedAlipay = item;
            this.closeAlipaySelect();
        },
        closeAlipaySelect() {
            this.dialogAlipaySelect = false;
        },
        removeAlipayAddress() {
            this.selectedAlipay = {}
        },

        //Bank 地址选择弹出框
        openBankSelect() {
            this.dialogBankSelect = this.BankList.length > 0;
        },
        selectBankAddress(item) {
            this.selectedBank = item;
            this.closeBankSelect();
        },
        closeBankSelect() {
            this.dialogBankSelect = false;
        },
        removeBankAddress() {
            this.selectedBank = {}
        },

        //Gopay 地址选择弹出框
        openGopaySelect() {
            this.dialogGopaySelect = this.GopayList.length > 0;
        },
        selectGopayAddress(item) {
            this.selectedGopay = item;
            this.closeGopaySelect();
        },
        closeGopaySelect() {
            this.dialogGopaySelect = false;
        },
        removeGopayAddress() {
            this.selectedGopay = {}
        },

        //Topay 地址选择弹出框
        openTopaySelect() {
            this.dialogTopaySelect = this.TopayList.length > 0;
        },
        selectTopayAddress(item) {
            this.selectedTopay = item;
            this.closeTopaySelect();
        },
        closeTopaySelect() {
            this.dialogTopaySelect = false;
        },
        removeTopayAddress() {
            this.selectedTopay = {}
        },

        // Okpay 地址选择弹出框
        openOkpaySelect() {
            this.dialogOkpaySelect = this.OkpayList.length > 0;
        },
        selectOkpayAddress(item) {
            this.selectedOkpay = item;
            this.closeOkpaySelect();
        },
        closeOkpaySelect() {
            this.dialogOkpaySelect = false;
        },
        removeOkpayAddress() {
            this.selectedOkpay = {}
        },

        // 获取用户提现地址
        getWithdrawal() {
            let that = this;
            this.$parent.showLoading();
            // 获取okpay提现地址
            that.$apiFun.post('/api/getcard', {
                type: 2
            }).then(res => {
                if (res.code == 200) {
                    // that.OkpayList = res.data.filter(item => item.bank.toLowerCase() === 'okpay')
                    that.OkpayList = res.data.filter(item => item.kinds === 2)
                    console.log("OkpayList", that.OkpayList);
                }
                this.$parent.hideLoading();
            });
            // 获取topay提现地址
            that.$apiFun.post('/api/getcard', {
                type: 1
            }).then(res => {
                if (res.code == 200) {
                    // that.TopayList = res.data.filter(item => item.bank.toLowerCase() === 'topay')
                    that.TopayList = res.data.filter(item => item.kinds === 1)
                    console.log("TopayList", that.TopayList);
                }
                this.$parent.hideLoading();
            });
            // 获取gopay提现地址
            that.$apiFun.post('/api/getcard', {
                type: 8
            }).then(res => {
                if (res.code == 200) {
                    // that.GopayList = res.data.filter(item => item.bank.toLowerCase() === 'gopay')
                    that.GopayList = res.data.filter(item => item.kinds === 8)
                    console.log("GopayList", that.GopayList);
                }
                this.$parent.hideLoading();
            });
            // 获取支付宝
            that.$apiFun.post('/api/getcard', {
                type: 10
            }).then(res => {
                if (res.code == 200) {
                    that.AlipayList = res.data.filter(item => item.kinds === 10)
                    console.log("AlipayList", that.AlipayList);
                }
                this.$parent.hideLoading();
            });
            // 获取银行卡
            that.$apiFun.post('/api/getcard', {
                type: 11
            }).then(res => {
                if (res.code == 200) {
                    that.BankList = res.data.filter(item => item.kinds === 11)
                    console.log("BankList", that.BankList);
                }
                this.$parent.hideLoading();
            });
        },
        // 获取接入支付渠道方式
        getPayWay() {
            let that = this;
            that.$parent.showLoading();
            that.$apiFun
                .get('/api/get_pay_way', {})
                .then(res => {
                    if (res.code == 200) {
                        that.payWayList = res.data.filter(item => item.key !== 1);
                        that.visiblePayWayList = that.showAll ? that.payWayList : that.payWayList.slice(0, 8);
                        if (that.payWayList.length > 0) {
                            that.checkedType = that.payWayList[0];
                            const min_price = that.checkedType.min_price || 100;
                            const max_price = that.checkedType.max_price || 10000;
                            that.amountPlaceholder = "请输入取款金额, " + min_price + " ~ " + max_price
                            that.payRange = [min_price, max_price]
                        }
                    }
                    that.$parent.hideLoading();
                })
                .catch(res => {
                    that.$parent.hideLoading();
                });
        },
        getBetAmount() {
            let that = this;
            that.$apiFun
                .post('/api/getBetAmount', {})
                .then(res => {
                    if (res.code != 200) {
                        that.$parent.showTost(0, res.message);
                    }
                    if (res.code == 200) {
                        that.betAmount = res.data.bet_amount;
                    }
                })
                .catch(res => {});
        },
        bigMey(val) {
            this.amount = val * 1;
        },
        changShow() {
            this.show = !this.show;
        },
        changShowLis(val) {
            this.showLis = val;
        },
        getbalancelist() {
            let that = this;
            that.$parent.showLoading();

            that.$apiFun
                .post('/api/balancelist', {})
                .then(res => {
                    if (res.code !== 200) {
                        that.$parent.showTost(0, res.message);
                    }
                    if (res.code === 200) {
                        that.balancelist = res.data;
                        let balancelist = res.data;
                        that.balancelist = balancelist;
                    }
                    that.$parent.hideLoading();
                })
                .catch(res => {
                    that.$parent.hideLoading();
                });
        },
        getbalancelistNoLoding() {
            let that = this;

            that.$apiFun
                .post('/api/balancelist', {})
                .then(res => {
                    if (res.code !== 200) {
                        that.$parent.showTost(0, res.message);
                    }
                    if (res.code === 200) {
                        that.balancelist = res.data;
                        let balancelist = res.data;
                        that.balancelist = balancelist;
                    }
                })
                .catch(res => {});
        },
        transall() {
            let that = this;
            that.$parent.showLoading();
            that.$apiFun
                .post('/api/transall', {})
                .then(res => {
                    if (res.code !== 200) {
                        that.$parent.showTost(0, res.message);
                    } else {
                        that.$parent.showTost(1, "操作成功");
                        that.getbalancelist();
                        that.refreshusermoney();
                        that.$parent.hideLoading();
                    }
                })
                .catch(res => {
                    that.$parent.hideLoading();
                });
        },

        withdraw(type) {
            let that = this;

            let cardid = null;
            let money = that.amount;
            let password = that.password;
            let channel = type.mch_id;

            // if (type.content.toLowerCase() === 'topay') {
            if (type.id === '1' || type.id === 1) {
                console.log("topay取款", that.selectedTopay);
                cardid = that.selectedTopay.id;
                // } else if (type.content.toLowerCase() === 'okpay') {
            } else if (type.id === '2' || type.id === 2) {
                console.log("okpay取款", that.selectedOkpay);
                cardid = that.selectedOkpay.id;
                // } else if (type.content.toLowerCase() === 'gopay') {
            } else if (type.id === '8' || type.id === 8) {
                console.log("gopay取款", that.selectedGopay);
                cardid = that.selectedGopay.id;
                // } else if (type.content.toLowerCase() === 'bank') {
            } else if (type.id === '10' || type.id === 10) {
                console.log("alipay取款", that.selectedAlipay);
                cardid = that.selectedAlipay.id;
            } else if (type.id === '11' || type.id === 11) {
                console.log("bank取款", that.selectedBank);
                cardid = that.selectedBank.id;
            }

            // else {
            //     that.$parent.showTost(0, '未知提现方式');
            //     return;
            // }

            if (!cardid) {
                that.$parent.showTost(0, '请选择您要提现到的地址');
                return;
            }
            // if (money < 100) {
            //     that.$parent.showTost(0, '单笔取款不能低于100元');
            //     return;
            // }
            console.log(money, that.payRange)
            if (money < Number(that.payRange[0]) || money > Number(that.payRange[1])) {
                that.$parent.showTost(0, `请确认金额在${that.payRange[0]}-${that.payRange[1]}之间！`);
                return;
            }

            if (money > this.$store.state.userInfo.balance) {
                that.$parent.showTost(0, '取款不能超过中心钱包余额');
                return;
            }

            if (!password) {
                that.$parent.showTost(0, '请输入您的支付密码');
                return;
            }
            that.$parent.showLoading();
            that.$apiFun
                .post(type.txaddress, {
                    money,
                    cardid,
                    password,
                    channel
                })
                .then(res => {
                    if (res.code != 200) {
                        that.$parent.showTost(0, res.message);
                    }
                    if (res.code == 200) {
                        that.$parent.showTost(1, '提交成功，等待后台审核');
                        that.$router.replace({
                            path: '/user'
                        });
                    }
                    that.$parent.hideLoading();
                })
                .catch(res => {
                    that.$parent.showTost(0, "提交失败，异常！");
                    that.$parent.hideLoading();
                });
        },

    },
    mounted() {
        let that = this;
    },
    updated() {
        let that = this;
    },
    beforeDestroy() {
        let that = this;
        if (that.daoTime) {
            clearInterval(that.daoTime);
        }
        that.daoTime = null;
    },
};
</script>

<style lang="scss" scoped>
/* 背景遮罩 */
.iframe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* 半透明的黑色背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* 确保在最上层 */
    padding: 20px;
    /* 防止极端情况下iframe过大溢出 */
}

/* 弹窗内容 */
.iframe-content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    /* 设置最大宽度限制，防止过宽 */
    max-height: 800px;
    /* 设置最大高度，防止过高 */
    border-radius: 8px;
    /* 圆角设计 */
    overflow: hidden;
    /* 确保 iframe 不会溢出对话框 */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    /* 阴影效果 */
    background-color: #fff;
}

/* 关闭按钮样式 */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.close-btn:hover {
    color: #f00;
    /* 鼠标悬停时按钮变红 */
}

/* iframe 样式，确保它适应对话框大小 */
iframe {
    border-radius: 4px;
    /* 圆角 */
}

// 弹出框样式
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog {
    background: #fff;
    padding: 20px;
    width: 90%;
    border-radius: 8px;
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}

.dialog-body {
    margin-top: 20px;
}

.dialog-body ul {
    list-style: none;
    padding: 0;
}

.dialog-body li {
    padding: 10px;
    cursor: pointer;
}

.dialog-body li:hover {
    background-color: #f0f0f0;
}

.dialog-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dialog-item {
    font-size: 14px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
}

.dialog-item:hover {
    transform: translateY(-5px);
    background-color: #f9f9f9;
}

.dialog-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.bank-owner {
    font-weight: bold;
    color: #2a78c1;
    margin-right: 8px;
    /* 和地址之间留出空隙 */
}

.bank-address {
    color: #555;
    word-break: break-all;
    /* 防止长地址溢出 */
}

.del-button {
    font-size: 14px;
    padding: 6px 12px;
    background-color: red;
    /* Orange color for "Close" button */
    color: white;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

@media (max-width: 600px) {
    .topay-item {
        font-size: 12px;
        padding: 10px;
    }
}

.custom-tag {
    font-size: 14px;
    /* 设置字体大小 */
    color: #333;
    /* 字体颜色 */
    padding: 6px 12px;
    /* 设置内边距，增强舒适感 */
    border-radius: 8px;
    /* 圆角设计，柔化边缘 */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    /* 添加微弱的阴影效果，使组件更立体 */
    background-color: #f4f8ff;
    /* 浅蓝色背景，使其更温和 */
    display: flex;
    /* 使用 Flexbox 排列元素 */
    align-items: center;
    /* 垂直居中 */
    justify-content: space-between;
    /* 两端对齐：地址信息和关闭按钮 */
}

/* bank-owner 样式 */
.custom-tag .bank-owner {
    font-weight: bold;
    /* 强调 bank_owner 字段 */
    color: #597ef7;
    /* 蓝色，突出显示 */
    margin-right: 6px;
    /* 增加间距，使内容不紧凑 */
}

/* bank-address 样式 */
.custom-tag .bank-address {
    color: #4a4a4a;
    /* 深灰色，显示地址 */
    word-break: break-word;
    /* 防止长地址溢出 */
}

/* 关闭按钮样式 */
.custom-tag .van-tag__close {
    color: #ff4d4f;
    /* 红色，突出关闭按钮 */
    font-size: 18px;
    /* 增大关闭按钮 */
    cursor: pointer;
    /* 鼠标悬停时显示点击手势 */
    transition: color 0.3s ease;
    /* 平滑过渡效果 */
}

.custom-tag .van-tag__close:hover {
    color: #f00;
    /* 鼠标悬停时关闭按钮变为红色 */
}

.tabVox {
    background: #f1f1f1;
    display: flex;
    justify-content: space-between;
    height: 1.2rem;
    align-items: center;
    padding-top: 5px;

    .tab {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.4rem;
    }

    .tab.atc {
        background: #fff;
        border-radius: 10px 10px 0 0;
        color: #597ef7;
        position: relative;
    }

    .atc::after {
        content: ' ';
        position: absolute;
        bottom: 2px;
        width: 30px;
        height: 3px;
        left: calc(50% - 15px);
        z-index: 200;
        background: #597ef7;
        border-radius: 4px;
    }
}

.qibao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.4rem;
    box-sizing: border-box;
    border-bottom: 1px solid #f8f8f8;

    .fes {
        font-size: 0.4rem;
        font-weight: 700;
    }

    .imgs {
        flex: 1;

        img {
            width: 0.4rem;
            margin-left: 0.1rem;
            display: flex;
            align-items: center;
        }
    }

    .btns {
        color: #697b8c;
        font-size: 0.28rem;
    }
}

.mesg {
    display: flex;
    align-items: center;
    height: 2rem;
    border-bottom: 1px solid #f8f8f8;

    .bosgf {
        flex: 1;
        text-align: center;

        .top {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.4rem;

            img {
                width: 0.4rem;
                margin-right: 0.1rem;
            }
        }

        .bots {
            margin-top: 0.1rem;
            font-size: 0.5rem;
            color: #597ef7;

            span {
                font-size: 0.23rem;
            }
        }
    }
}

.gameBox {
    display: flex;
    flex-wrap: wrap;

    .lis {
        width: 25%;
        text-align: center;
        box-sizing: border-box;
        padding-top: 0.4rem;

        .name {
            font-size: 0.23rem;
            color: #383b43;
            line-height: 1;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //显示省略号
            white-space: nowrap; //强制不换行
        }

        .num {
            font-size: 0.23rem;
            //color: #cbced8;
            color: #597ef7;
            margin-top: 0.2rem;
        }

        img {
            width: 0.32rem;
        }
    }
}

.usrse {
    background: #fff;
    box-sizing: border-box;
    padding-top: 5px;

    .hgs {
        width: calc(100% - 40px);
        margin: 0 auto;
    }

    .nams {
        font-size: 0.38rem;
        color: #000;
        vertical-align: middle;
        margin-top: 10px;
        font-weight: 700;
    }

    .imgsa {
        position: relative;
        height: 2rem;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 0.2rem;

        .bisn {
            width: 0.8rem;
            position: absolute;
            bottom: 0.3rem;
            left: 1.4rem;
        }

        img {
            width: 2rem;
            border-radius: 50%;
        }
    }
}

.card {
    .lis {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        margin: 0 auto;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 10px 25px;
        min-height: 80px;
        border-radius: 10px;
        position: relative;
        border-radius: 0.16rem;
        background-color: #f8f9ff;
        -webkit-box-shadow: 0 0.04rem 0.2rem rgb(199 212 255 / 42%);
        box-shadow: 0 0.04rem 0.2rem rgb(199 212 255 / 42%);
        border: 0.02rem solid #fff;

        .lefs {
            width: 1.5rem;
        }

        .cest {
            flex: 1;
            margin: 0 20px;

            .type {
                font-size: 0.4rem;
                font-weight: 700;
                color: #98a8c5;
                margin-top: 6px;
            }

            .num {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 6px;
                color: #98a8c5;

                span {
                    font-size: 0.6rem;
                }
            }
        }

        .rigss {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 0.6rem;
        }
    }
}

.poptit {
    height: 1.4rem;
    font-size: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 22;
}

.cardhgs {
    display: flex;
    align-items: center;
    color: #597ef7;
    font-size: 0.23rem;
    height: 1.2rem;

    img {
        width: 0.8rem;
        margin: 0 1rem;
    }

    span {
        display: inline-block;
        margin: 0 4px;
        font-size: 0.23rem;
    }
}

/* 优化后的按钮样式 */
.deposit-button {
    //   background-color: green; /* 设置主按钮的背景色 */
    border-radius: 8px;
    /* 增加圆角 */
    color: white;
    /* 按钮文字颜色 */
    font-size: 16px;
    /* 增大字体尺寸 */
    font-weight: bold;
    /* 设置字体加粗 */
    padding: 12px 20px;
    /* 按钮内边距，确保内容不拥挤 */
    transition: all 0.3s ease;
    /* 添加过渡效果 */
    border: none;
    /* 去除默认边框 */
}

/* 悬停效果 */
.deposit-button:hover {
    //   background-color: green; /* 悬停时的背景色变化 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* 按钮悬停时增加阴影效果 */
}

/* 点击效果 */
.deposit-button:active {
    //   background-color: green; /* 按钮点击时的背景色 */
    transform: scale(0.98);
    /* 按钮点击时微缩效果 */
}

.pay-way-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 20px;
    background: #fff;
}

.pay-way-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: box-shadow 0.2s;
}

.pay-way-item.active {
    border-color: #597ef7;
    box-shadow: 0 2px 6px rgba(49, 134, 214, 0.952);
}

.pay-way-title {
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
    color: #333;
    margin-bottom: 6px;
    font-size: 14px;
    text-align: center;
    color: #333;
    line-height: 1.2;
}

.expand-label {
    color: #007BFF;
    cursor: pointer;
    font-size: 14px;
    display: block;
    text-align: center;
}

// 配置按钮
.btn {
    border-radius: 6px;
}
</style>
