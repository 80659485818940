<template>
<div style="width: 100%; min-height: 100vh; background: rgb(237, 241, 255)">
    <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd" :title="`新增${bank}地址`" left-arrow @click-left="$router.back()" />
    <div style="height: 46px"></div>
    <div class="usrse" style="line-height: 1.5;">
        <div class="hgs">
            <div v-if="bank.toLowerCase() == 'alipay'" class="nams"> 账号备注 </div>
            <div v-else class="nams">地址备注</div>
            <div>
                <div data-v-a12ec382="" class="van-cell-group van-hairline--top-bottom">
                    <div data-v-a12ec382="" class="van-cell van-field">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body">
                                <!-- <input type="text" readonly onfocus="this.removeAttribute('readonly');" auto-complete="off" onblur="this.setAttribute('readonly',true);" v-model="cardInfo.bank_owner" placeholder="请输入备注" class="van-field__control" /> -->
                                <input type="text" v-model="cardInfo.bank_owner" placeholder="请输入备注" class="van-field__control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="usrse" style="line-height: 1.5;">
        <div class="hgs">
            <div v-if="bank.toLowerCase() == 'alipay'" class="nams">支付宝账号</div>
            <div v-else class="nams">{{bank}}地址</div>
            <div style="border-bottom: 1px solid #f2f2f2">
                <div data-v-a12ec382="" class="van-cell-group van-hairline--top-bottom">
                    <div data-v-a12ec382="" class="van-cell van-field">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body">
                                <!-- <input v-if="bank.toLowerCase() == 'alipay'" type="text" readonly onfocus="this.removeAttribute('readonly');" auto-complete="off" onblur="this.setAttribute('readonly',true);" v-model="cardInfo.bank_no" :placeholder="`请输入支付宝账号`" class="van-field__control" />
                                <input v-else type="text" readonly onfocus="this.removeAttribute('readonly');" auto-complete="off" onblur="this.setAttribute('readonly',true);" v-model="cardInfo.bank_no" :placeholder="`请输入${bank}地址`" class="van-field__control" />
                            -->
                                <input v-if="bank.toLowerCase() == 'alipay'" type="text" v-model="cardInfo.bank_no" :placeholder="`请输入支付宝账号`" class="van-field__control" />
                                <input v-else type="text" v-model="cardInfo.bank_no" :placeholder="`请输入${bank}地址`" class="van-field__control" />
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hgs" style="line-height: 1.5;">
            <div class="nams">支付密码验证</div>
            <div style="border-bottom: 1px solid #f2f2f2">
                <van-cell-group>
                    <van-field v-model="cardInfo.pay_pass" type="password" placeholder="请输入支付密码" />
                </van-cell-group>
            </div>
        </div>
        <van-button type="info" style="margin-top: 20px; width: 100%" @click="bindCard">确认添加</van-button>
        <div style="height: 60px"></div>
    </div>

</div>
</template>

<script>
export default {
    name: 'addBankCard',
    data() {
        return {
            cardInfo: {},
            bank: "",
            kinds: ""
        };
    },
    created() {
        let that = this;
        that.bank = that.$route.params.bank
        that.kinds = that.$route.params.kinds
    },
    methods: {
        bindCard() {
            let that = this;
            that.cardInfo.bank = that.bank
            that.cardInfo.kinds = that.kinds
            if (!that.cardInfo.bank_owner) {
                that.$parent.showTost(0, '请输入备注');
                return;
            }
            if (!that.cardInfo.bank_no) {
                that.$parent.showTost(0, '请输入钱包地址');
                return;
            }
            if (!that.cardInfo.pay_pass) {
                that.$parent.showTost(0, '请输入支付密码');
                return;
            }
            if (that.cardInfo.pay_pass.length < 6 || that.cardInfo.pay_pass.length > 18) {
                that.$parent.showTost(0, '请输入6-18位支付密码');
                return;
            }
            that.$parent.showLoading();
            that.$apiFun
                .post('/api/bindcard', that.cardInfo)
                .then(res => {
                    if (res.code != 200) {
                        that.$parent.showTost(0, res.message);
                    }
                    if (res.code == 200) {
                        that.$parent.showTost(1, '绑定成功');
                        that.$router.back();
                    }
                    that.$parent.hideLoading();
                })
                .catch(res => {
                    that.$parent.hideLoading();
                });
        },
    },
    mounted() {
        let that = this;
    },
    updated() {
        let that = this;
    },
};
</script>

<style lang="scss" scoped>
.usrse {
    background: #fff;
    box-sizing: border-box;
    padding: 6px 20px 0;

    .nams {
        font-size: 0.38rem;
        color: #000;
        vertical-align: middle;
        margin-top: 10px;
        font-weight: 700;
    }

    .imgsa {
        position: relative;
        height: 2rem;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 0.2rem;

        .bisn {
            width: 0.8rem;
            position: absolute;
            bottom: 0.3rem;
            left: 1.4rem;
        }

        img {
            width: 2rem;
            border-radius: 50%;
        }
    }
}

[class*='van-hairline']:after {
    border: none;
}
</style>
