<template>
<div class="sdg sdgg" style="width: 100%; min-height: 100vh; background: rgb(237, 241, 255); padding-bottom: 50px">
    <div style="width: 100%; background: #fff">
        <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd" title="存款" left-arrow @click-left="$router.back()" />
        <div style="height: 46px"></div>
        <!-- 存款方式选择 -->

        <div class="pay-way-grid">
            <div v-for="(type, index) in visiblePayWayList" :key="index" class="pay-way-item" :class="{ active: activeTabIndex === index }" @click="onClickTab(index, type)">
                <div style="text-align: center;">
                    <van-image v-if="type.payimg" :src="type.payimg" width="40px" height="40px" />
                    <div class="pay-way-title">{{ type.content }}</div>
                </div>
            </div>

        </div>

        <!-- 如果有更多内容，则显示“展开更多” -->
        <span v-if="payWayList.length > 8" class="expand-label" @click="toggleExpand">
            {{ showAll ? '收起' : '展开更多' }}
        </span>

        <div>
            <div class="usrse">
                <div style="background: #fff; box-sizing: border-box; padding: 0 20px">
                    <div class="hgs">
                        <div class="nams" style="display: flex; align-items: center;">
                            存款金额
                            <div v-if="amount" style="color: green; margin-left: 5px; line-height: 1.5;">：￥{{ amount }}</div>
                        </div>
                        <div v-if="checkedType.gded === 2">
                            <!-- 区间 -->
                            <van-cell-group>
                                <van-field v-model="amount" type="text" :placeholder="amountPlaceholder" @input="handleInput2" />
                            </van-cell-group>
                        </div>
                        <div v-if="checkedType.gded === 1">
                            <!-- 固定 -->
                            <div style="display: flex; flex-wrap: wrap; gap: 10px; padding-top: 10px;">
                                <div v-for="(item, index) in moneyList" :key="index" class="money-item" :class="{'checked': amount === item}" @click="selectAmount(item)">
                                    ￥{{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin: 0 auto; width: 86%">
                <van-button type="info" style="margin-top: 20px; width: 100%" @click="payTest(checkedType)" class="deposit-button"> 立即存款</van-button>
                <div class="textcns" style="text-align: center; color: #999; padding: 10px 0;font-size: medium;line-height: 1.2;">USDT存款？存款遇到问题？<br>联系 <span @click="$parent.openKefu" style="color: #597ef7; display: inline-block; margin: 0 6px">人工客服</span> 解决</div>
            </div>

            <div v-if="TopayList.length == 0 && checkedType.content && checkedType.content.toLowerCase() === 'topay'">
                <div class="domainModal_top__1omYS" style="padding-left: 10px;padding-top: 10px;font-size: large;font-weight: 600;">温馨提示</div>
                <div class="domainModal_middle__3gQPm" style="padding: 20px; text-align: center; font-size: medium;display: flex;justify-content: center;">您还未绑定任何钱包卡片，请
                    <h1 @click="$parent.goNav('/addTopay')" style="color: #597ef7;padding-left: 5px;font-weight: 500;">前往绑定</h1> ！
                </div>
            </div>
            <div v-if="GopayList.length == 0 && checkedType.content && checkedType.content.toLowerCase() === 'gopay'">
                <div class="domainModal_top__1omYS" style="padding-left: 10px;padding-top: 10px;font-size: large;font-weight: 600;">温馨提示</div>
                <div class="domainModal_middle__3gQPm" style="padding: 20px; text-align: center; font-size: medium;display: flex;justify-content: center;">您还未绑定任何钱包卡片，请
                    <h1 @click="$parent.goNav('/addGopay')" style="color: #597ef7;padding-left: 5px;font-weight: 500;">前往绑定</h1> ！
                </div>
            </div>
            <div v-if="OkpayList.length == 0 && checkedType.content && checkedType.content.toLowerCase() === 'okpay'">
                <div class="domainModal_top__1omYS" style="padding-left: 10px;padding-top: 10px;font-size: large;font-weight: 600;">温馨提示</div>
                <div class="domainModal_middle__3gQPm" style="padding: 20px; text-align: center; font-size: medium;display: flex;justify-content: center;">您还未绑定任何钱包卡片，请
                    <h1 @click="$parent.goNav('/addOkpay')" style="color: #597ef7;padding-left: 5px; font-weight: 500;">前往绑定</h1> ！
                </div>
            </div>
        </div>

    </div>
    <div v-if="iframeSrc" class="iframe-overlay" scrolling="no">
        <div class="iframe-content">
            <button class="close-btn" @click="closeIframe">×</button>
            <iframe id="myIframe" :src="iframeSrc" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
    <!-- <div v-if="iframeSrc" class="iframe-container">
        <iframe ref="myIframe" :src="iframeSrc" class="responsive-iframe"></iframe>
    </div> -->

</div>
</template>

<script>
export default {
    name: 'recharge',
    data() {
        return {
            checkedType: {},

            GopayList: [],
            TopayList: [],
            OkpayList: [],

            payInfo: {},
            amount: null,
            // password: null,
            bankBox: {},
            payWayList: {},
            amountPlaceholder: "请输入存款金额",
            payRange: {},
            moneyList: [],
            iframeSrc: null,
            activeTabIndex: 0,

            showAll: false, // 控制是否显示所有项
            visiblePayWayList: [],
        };
    },
    created() {
        let that = this;
        that.getPayWay();
        that.getRechargeAddress();
    },
    mounted() {
        this.$refs.myIframe.onload = this.adjustIframeScale;
    },
    watch: {
        // 监听路由变化
        '$route'(to, from) {
            // 路由变化时重新请求数据
            if (to.path === '/recharge') {
                this.activeTabIndex = 0
                this.amount = null
                this.getPayWay();
                this.getRechargeAddress();
            }

        },
    },
    methods: {
        toggleExpand() {
            let that = this;
            that.showAll = !that.showAll; // 切换显示更多或收起
            that.visiblePayWayList = that.showAll ? that.payWayList : that.payWayList.slice(0, 8);
        },
        // 点击金额时触发
        selectAmount(item) {
            // 将选中的金额赋值给 amount
            this.amount = item;
        },
        // 100倍数限制
        handleInput1() {
            let value = this.amount;
            // 如果值小于 0，设置为 0
            if (parseFloat(value) < 0) {
                value = 0;
            }
            // 如果大于100 ，则计算只能100的倍数
            if (value > 100) {
                value = Math.round(value / 100) * 100;
            }
            // 如果大于2位小数，则保留2位小数
            if (value.toString().indexOf('.') !== -1) {
                const decimalPlaces = value.toString().split('.')[1].length;
                if (decimalPlaces > 2) {
                    value = value.split('.')[0] + '.' + value.split('.')[1].slice(0, 2);
                    value = parseFloat(value);

                }
            }
            this.amount = value;
        },
        // 区间限制
        handleInput2() {
            let value = this.amount;

            // 如果值小于 0，设置为 0
            if (parseFloat(value) < 0) {
                value = 0;
            }
            // 如果大于2位小数，则保留2位小数
            if (value.toString().indexOf('.') !== -1) {
                const decimalPlaces = value.toString().split('.')[1].length;
                if (decimalPlaces > 2) {
                    value = value.split('.')[0] + '.' + value.split('.')[1].slice(0, 2);
                    value = parseFloat(value);

                }
            }
            this.amount = value;
        },
        onClickTab(tab, type) {
            let that = this;
            that.activeTabIndex = tab;
            that.checkedType = type;
            // that.checkedType.content = that.checkedType.content.toLowerCase();
            // that.amount = that.payWayList[tab].min_price;
            const min_price = that.payWayList[tab].min_price || 100;
            const max_price = that.payWayList[tab].max_price || 10000;
            that.amountPlaceholder = "请输入存款金额, " + min_price + " ~ " + max_price
            that.amount = ""
            if (that.payWayList[tab].gded === 1) {
                that.moneyList = []
                // that.handleInput1();
                console.log("区间", min_price + " ~ " + max_price)
                const num = (Number(max_price) - Number(min_price)) / 100;
                for (let i = 0; i <= num; i++) {
                    that.moneyList[i] = Number(min_price) + Number(100 * i)
                }
                console.log("that.moneyList", that.moneyList)
            } else if (that.payWayList[tab].gded === 2) {
                that.handleInput2();
            }

        },
        openIframe(url) {
            try {
                console.log("openIframe", url)
                const protocol = window.location.protocol;
                console.log("协议：", protocol)
                if (url.startsWith(protocol)) {
                    this.$parent.goNav(`/rechargePage?backIndex=false&url=${url}`)
                } else {
                    window.location.href = url
                }
            } catch (error) {
                that.$parent.showTost(0, error.message);
                that.$parent.showTost(0, "暂时无法提供服务，请选择其他方式或金额");
            }

            // this.iframeSrc = url;
            // window.location.href = url
            // window.open(url, '_blank');
        },
        closeIframe() {
            this.iframeSrc = null;
        },
        // 获取用户存款地址
        getRechargeAddress() {
            let that = this;
            this.$parent.showLoading();
            // 获取okpay 存款地址
            that.$apiFun.post('/api/getcard', {
                type: 2
            }).then(res => {
                if (res.code == 200) {
                    // that.OkpayList = res.data.filter(item => item.bank.toLowerCase() === 'okpay')
                    that.OkpayList = res.data.filter(item => item.kinds === 2)
                    console.log("OkpayList", that.OkpayList);
                }
                this.$parent.hideLoading();
            });
            // 获取topay 存款地址
            that.$apiFun.post('/api/getcard', {
                type: 1
            }).then(res => {
                if (res.code == 200) {
                    // that.TopayList = res.data.filter(item => item.bank.toLowerCase() === 'topay')
                    that.TopayList = res.data.filter(item => item.kinds === 1)
                    console.log("TopayList", that.TopayList);
                }
                this.$parent.hideLoading();
            });
            // 获取gopay 存款地址
            that.$apiFun.post('/api/getcard', {
                type: 8
            }).then(res => {
                if (res.code == 200) {
                    // that.GopayList = res.data.filter(item => item.bank.toLowerCase() === 'gopay')
                    that.GopayList = res.data.filter(item => item.kinds === 8)
                    console.log("GopayList", that.GopayList);
                }
                this.$parent.hideLoading();
            });
        },
        // 获取接入支付渠道方式
        getPayWay() {
            let that = this;
            that.showLoading();

            that.$apiFun
                .get('/api/get_pay_way', {})
                .then(res => {
                    if (res.code == 200) {
                        that.payWayList = res.data.filter(item => item.key !== 2);
                        that.visiblePayWayList = that.showAll ? that.payWayList : that.payWayList.slice(0, 8);
                        let obj = that.payWayList;
                        let payRangeData = {};
                        for (let i in obj) {
                            let payWayData = obj[i];
                            const min_price = payWayData.min_price || 100;
                            const max_price = payWayData.max_price || 10000;

                            if (i === "0") {
                                that.checkedType = payWayData;
                                // that.checkedType.content = that.checkedType.content.toLowerCase();
                                // that.amount = payWayData.min_price
                                that.amountPlaceholder = "请输入存款金额, " + min_price + " ~ " + max_price
                            }
                            const key = payWayData.content.toLowerCase();
                            payRangeData[key] = [min_price, max_price];
                        }
                        that.payRange = payRangeData;
                    }
                    that.hideLoading();
                })
                .catch(res => {
                    that.hideLoading();
                })

        },
        // 存款
        payTest(type) {
            let paytype = type.content.toLowerCase();
            let that = this;
            let info = {
                paytype: paytype,
                money: that.amount * 1,
                // password: that.password,
                channel: type.mch_id
            };
            console.log("that.payRange", that.payRange)
            // 金额判断
            if (info.money < that.payRange[paytype][0] || info.money > that.payRange[paytype][1]) {
                that.showTost(0, `请确认金额在${that.payRange[paytype][0]}-${that.payRange[paytype][1]}之间！`);
                return;
            }
            that.showLoading();

            that.$apiFun
                .post(type.regaddress, info)
                .then(res => {
                    console.log(res);
                    if (res.code != 200) {
                        console.log(201, res);
                        that.showTost(0, res.message);
                    }
                    if (res.code == 200) {
                        console.log(200, res);
                        that.openIframe(res.data.navurl)
                    }
                    that.hideLoading();
                })
                .catch(res => {
                    that.hideLoading();
                });

        },
        changMey(val) {
            this.amount = val * 1;
        },
        goNav(url) {
            let that = this;
            that.$parent.goNav(url);
        },
        doCopy(msg) {
            let cInput = document.createElement('input');
            cInput.style.opacity = '0';
            cInput.value = msg;
            document.body.appendChild(cInput);
            // 选取文本框内容
            cInput.select();

            // 执行浏览器复制命令
            // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
            // Input要在正常的编辑状态下原生复制方法才会生效
            document.execCommand('copy');

            // 复制成功后再将构造的标签 移除
            this.showTost(1, '复制成功！');
        },
        getUserInfo() {
            let that = this;
            that.$parent.getUserInfo();
        },
        showLoading() {
            this.$parent.showLoading();
        },
        hideLoading() {
            this.$parent.hideLoading();
        },
        openKefu() {
            this.$parent.openKefu();
        },
        showTost(type, title) {
            this.$parent.showTost(type, title);
        },
    },
    mounted() {
        let that = this;
        that.amount = ""
    },
    updated() {
        let that = this;
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            console.log(vm);
            let that = this;
            // vm.getuseCardlist();
        });
    },
};
</script>

<style lang="scss" scoped>
@import '/style.css';

.iframe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.iframe-content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    max-height: 800px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

/* 关闭按钮样式 */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.close-btn:hover {
    color: #f00;
}

iframe {
    border-radius: 4px;
}

.usrse {
    background: #fff;
    box-sizing: border-box;
    padding-top: 5px;

    .hgs {
        width: calc(100% - 40px);
        margin: 0 auto;
    }

    .nams {
        font-size: 0.38rem;
        color: #000;
        vertical-align: middle;
        margin-top: 10px;
        font-weight: 700;
    }

    .imgsa {
        position: relative;
        height: 2rem;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 0.2rem;

        .bisn {
            width: 0.8rem;
            position: absolute;
            bottom: 0.3rem;
            left: 1.4rem;
        }

        img {
            width: 2rem;
            border-radius: 50%;
        }
    }
}

[class*='van-hairline']:after {
    border: none;
}

.money-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(25% - 10px);
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.money-item:hover {
    background-color: #e6f7ff;
    border-color: #91d5ff;
}

.money-item.checked {
    background-color: rgb(59, 139, 59);
    color: #fff;
    border-color: rgb(59, 139, 59);
}

.pay-way-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 20px;
    background: #fff;
}

.pay-way-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: box-shadow 0.2s;
}

.pay-way-item.active {
    border-color: rgb(59, 139, 59);
    box-shadow: 0 2px 6px rgba(110, 182, 103, 0.952);
}

.pay-way-title {
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
    color: #333;
    margin-bottom: 6px;
    font-size: 14px;
    text-align: center;
    color: #333;
    line-height: 1.2;
}

/* 优化后的按钮样式 */
.deposit-button {
    background-color: rgb(59, 139, 59);
    /* 设置主按钮的背景色 */
    border-radius: 8px;
    /* 增加圆角 */
    color: white;
    /* 按钮文字颜色 */
    font-size: 16px;
    /* 增大字体尺寸 */
    font-weight: bold;
    /* 设置字体加粗 */
    padding: 12px 20px;
    /* 按钮内边距，确保内容不拥挤 */
    transition: all 0.3s ease;
    /* 添加过渡效果 */
    border: none;
    /* 去除默认边框 */
}

/* 悬停效果 */
.deposit-button:hover {
    background-color: rgb(59, 139, 59);
    /* 悬停时的背景色变化 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* 按钮悬停时增加阴影效果 */
}

/* 点击效果 */
.deposit-button:active {
    background-color: rgb(59, 139, 59);
    /* 按钮点击时的背景色 */
    transform: scale(0.98);
    /* 按钮点击时微缩效果 */
}

.expand-label {
    color: #007BFF;
    cursor: pointer;
    font-size: 14px;
    display: block;
    text-align: center;
}
</style>
