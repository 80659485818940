<template>
<div>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <img src="/static/image/bg.aeffce071a77b9f3cadbb3e35832d14b.jpg" />

    <div class="container" v-if="$store.state.userInfo.isagent==1">

        <!-- Header Section -->
        <div class="header">
            <h2>合营中心</h2>
            <div class="sub-header">
                <span @click="backmain">退出</span>
            </div>
        </div>

        <!-- Overview Section -->
        <div class="overview">
            <h3>本月概览</h3>
            <div class="overview-item">
                <div v-if="stats.one && stats.one.length" class="overview-stats">
                    <div class="stat" v-for="(stat, index) in stats.one" :key="index">
                        <div class="label">{{ stat.label }}</div>
                        <div class="amount">{{ stat.amount }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="additional-stats">
            <div class="additional-stats-item box">
                <div v-if="stats.two && stats.two.length" class="additional-stats-content">
                    <div class="stat" v-for="(stat, index) in stats.two" :key="index">
                        <div class="label">{{ stat.label }}</div>
                        <div class="amount">{{ stat.amount }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Detailed Financials -->
        <div class="detailed-overview">
            <div class="tabs">
                <button :class="{'active': activeTab === 'tab1'}" @click="setActiveTab('tab1')">合营推广</button>
            </div>

            <!-- Tab Content -->
            <div v-if="activeTab === 'tab1'" class="tab-content">
                <div class="tab2-content">
                    <!-- Image Section with background image -->
                    <div class="image-section">
                        <div class="image-with-qrcode" :style="autoDownload ? '': 'display: none'">
                            <canvas ref="canvas" class="background-canvas" style="width: 100%;"></canvas> <!-- 用于绘制背景图和二维码 -->
                        </div>
                    </div>
                    <van-image v-if="showImageUrl && showImg" :src="showImageUrl" style="height: 100%;width: 100%" @click="downloadImage(showImageUrl)" />

                    <!-- Text Section -->
                    <div class="text-section">
                        <p id="link_area">{{ stats.regurl }}</p>
                    </div>

                    <!-- Button Section -->
                    <div class="button-section">
                        <button class="tab2-button" v-if="qrCodeImage" @touchstart="saveAsImage">{{ autoDownload ? '保存推广图' : '生成推广图' }}</button>
                        <button class="tab2-button" v-if="qrCodeImage" @touchstart="saveQRCodeImage">{{ autoDownload ? '保存二维码' : '生成二维码' }}</button>
                        <button class="tab2-button" @touchstart="copyLink">复制链接</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="container">
        <!-- Header Section -->
        <div class="header">
            <h2>合营中心</h2>
            <div class="sub-header">
                <span @click="backmain">退出</span>
            </div>
        </div>
        <div style="display: flex;text-align: center;justify-content: space-around;">
            <div>
                暂无权限
            </div>
        </div>

    </div>
</div>
</template>

<script>
import QRCode from 'qrcode';
import {
    saveAs
} from 'file-saver'; // 导入 FileSaver.js

export default {
    name: 'FinancialOverview',
    data() {
        return {
            activeTab: 'tab1',
            stats: {
                one: [],
                two: [],
                three: [],
                regurl: "", // URL用于生成二维码

            },
            qrCodeImage: "", // 用于存储二维码的图片URL
            shareImage: "",
            showImageUrl: "",
            showImg: false,
            autoDownload: false,
            task: null,
        };
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab;
        },
        backmain() {
            this.$router.push('/');
        },
        async fetchStats() {
            try {
                const response = await this.$apiFun.post("/api/agentme"); // 替换为实际 API
                if (response && response.data) {
                    this.imageUrl = response.data.rgImg
                    this.stats = {
                        one: response.data.one || [],
                        two: response.data.two || [],
                        three: response.data.three || [],
                        regurl: response.data.regurl
                    };
                    this.generateQRCode(); // 获取数据后生成二维码
                }
            } catch (error) {
                console.error("获取统计数据失败:", error);
                this.stats = {
                    one: [],
                    two: [],
                    three: []
                };
            }
        },
        copyLink() {
            const link = this.stats.regurl;
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(link).then(() => {
                    this.$parent.showTost(1, "复制成功！");
                }).catch((error) => {
                    const element = document.getElementById("link_area");
                    const range = document.createRange();
                    range.selectNodeContents(element); // 选中元素内容
                    // 获取当前的选区并清空它
                    const selection = window.getSelection();
                    selection.removeAllRanges(); // 清空所有选区
                    // 添加新的选区
                    selection.addRange(range); // 添加新的选区，选中元素内容
                    const successful = document.execCommand("copy")
                    if (successful) {
                        this.$parent.showTost(1, "复制成功！");
                    } else {
                        const errorMessage = `复制失败: ${error.message || "未知错误"}\n请手动复制选中链接部分`;
                        this.$parent.showTost(0, errorMessage);
                        console.error("复制失败原因:", error);
                    }

                });
            } else {
                const element = document.getElementById("link_area");
                if (element) {
                    const range = document.createRange();
                    range.selectNodeContents(element);
                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand('copy'); // 传统复制方法
                    this.$parent.showTost(1, "复制成功！");
                } else {
                    this.$parent.showTost(0, "复制失败: 当前环境不支持剪贴板操作\n请手动复制选中部分");
                }
            }

        },

        generateQRCode() {
            if (this.stats.regurl) {
                // 使用QRCode.toDataURL生成二维码的图片链接
                QRCode.toDataURL(this.stats.regurl, {
                    width: 200,
                    margin: 2
                }, (error, url) => {
                    if (error) {
                        console.error("二维码生成失败", error);
                    } else {
                        // 设置二维码图片的URL
                        this.qrCodeImage = url;
                        this.drawQRCode(); // 生成二维码后绘制到画布
                    }
                });
            }
        },
        drawQRCode() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');

            // 加载背景图片
            const backgroundImage = new Image();
            backgroundImage.src = '/static/image/1rc.png'; // 这里替换为实际背景图片路径

            backgroundImage.onload = () => {
                // 设置canvas的大小为背景图的大小
                const scaledWidth = backgroundImage.width * 0.75;
                const scaledHeight = backgroundImage.height * 0.75;

                // 绘制背景图片
                // 设置canvas的大小为背景图缩放后的大小
                canvas.width = scaledWidth;
                canvas.height = scaledHeight;

                ctx.drawImage(backgroundImage, 0, 0, backgroundImage.width, backgroundImage.height, 0, 0, scaledWidth, scaledHeight);
                // 绘制二维码
                const qrCodeImage = new Image();
                qrCodeImage.src = this.qrCodeImage;

                qrCodeImage.onload = () => {
                    const qrCodeWidth = 303; // 设置二维码的宽度
                    const qrCodeHeight = 303; // 设置二维码的高度
                    const qrCodeX = canvas.width - qrCodeWidth - 608; // 设置二维码的X坐标
                    const qrCodeY = canvas.height - qrCodeHeight - 47; // 设置二维码的Y坐标

                    // 绘制二维码
                    ctx.drawImage(qrCodeImage, qrCodeX, qrCodeY, qrCodeWidth, qrCodeHeight);
                    this.shareImage = canvas.toDataURL('image/png');

                };

            };

            console.log("qrcode", this.qrCodeImage)
            console.log("img", this.shareImage)
        },
        // 使用 FileSaver 保存二维码图片
        saveAsImage() {
            if (this.autoDownload) {
                try {
                    const canvas = this.$refs.canvas;

                    if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
                        console.error("Canvas 元素无效。");
                        this.$parent.showTost(0, "未找到 Canvas 元素。");
                        return;
                    }
                    // 使用 toBlob 生成图片数据
                    canvas.toBlob((blob) => {
                        if (blob) {
                            saveAs(blob, 'qrcode.png'); // 使用 FileSaver.js 库进行下载
                        } else {
                            console.error("无法生成图片 Blob 数据。");
                            this.$parent.showTost(0, "生成图片失败：无法生成数据。");
                        }
                    }, 'image/png');
                } catch (error) {
                    console.error("保存图片失败：", error);
                    const errorMessage = `保存图片失败: ${error.message || "未知错误"}`;
                    this.$parent.showTost(0, errorMessage);
                }
            } else {
                try {
                    this.$parent.showLoading();
                    this.task = setInterval(() => {
                        console.log("task saveAsImage")
                        if (this.shareImage !== '') {
                            clearTimeout(this.task)
                            this.showImg = true;
                            this.$parent.hideLoading();
                            this.showImageUrl = this.shareImage;
                            // this.$parent.showTost(1, "推广图已生成，点击图片下载，若未自动下载请长按选中后保存到设备");
                            this.$parent.showTost(1, "推广图已生成，点击图片下载");
                        }
                    }, 500);
                } catch (e) {
                    this.$parent.hideLoading();
                }
            }

        },

        // 使用 FileSaver 保存二维码图片
        saveQRCodeImage() {
            if (this.autoDownload) {
                try {
                    if (this.qrCodeImage && this.qrCodeImage.startsWith('data:image/')) {
                        // 提取 Base64 数据
                        const base64Data = this.qrCodeImage.split(',')[1];
                        const mimeType = this.qrCodeImage.match(/^data:(.*?);base64,/)[1];

                        // 将 Base64 转换为二进制数据
                        const binary = atob(base64Data);
                        const array = [];
                        for (let i = 0; i < binary.length; i++) {
                            array.push(binary.charCodeAt(i));
                        }

                        // 创建 Blob 对象
                        const blob = new Blob([new Uint8Array(array)], {
                            type: mimeType
                        });

                        // 使用 FileSaver 保存二维码图片
                        saveAs(blob, 'qrcode.png');
                    } else {
                        console.error("无效的二维码数据！");
                        this.$parent.showTost(0, "保存二维码失败：无效的二维码数据。");
                    }
                } catch (error) {
                    console.error("保存二维码失败：", error);
                    const errorMessage = `保存二维码失败: ${error.message || "未知错误"}`;
                    this.$parent.showTost(0, errorMessage);
                }
            } else {
                try {
                    this.$parent.showLoading();
                    this.task = setInterval(() => {
                        console.log("task saveQRCodeImage")
                        if (this.shareImage !== '') {
                            clearTimeout(this.task)
                            this.showImg = true;
                            this.$parent.hideLoading();
                            this.showImageUrl = this.qrCodeImage;
                            // this.$parent.showTost(1, "二维码已生成，点击图片下载，若未自动下载请长按选中后保存到设备");
                            this.$parent.showTost(1, "二维码已生成，点击图片下载");
                        }
                    }, 500);
                } catch (e) {
                    this.$parent.hideLoading();
                }

            }

        },
        downloadImage(url) {
            this.$dialog
                .confirm({
                    title: '下载图片',
                    message: '若无法成功下载请长按选中后保存到设备',
                })
                .then(() => {
                    // 创建一个临时的 <a> 标签
                    // 去掉前缀部分 "data:image/png;base64,"
                    console.log(url)
                    const base64Data = url.split(',')[1];
                    const byteCharacters = atob(base64Data);

                    // 将 base64 解码后的字节数组转换为二进制数据
                    const byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);
                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }

                    // 创建一个 Blob 对象
                    const blob = new Blob(byteArrays, {
                        type: 'image/png'
                    });
                    // 创建一个链接，并设置 Blob URL
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'downloaded_image.png'; // 设置下载文件名
                    // 模拟点击链接触发下载
                    link.click();
                    // 释放 Blob 对象
                    URL.revokeObjectURL(link.href);
                })
                .catch(() => {});
        }
    },
    mounted() {
        this.fetchStats(); // 页面加载时调用数据获取方法
        // 能够直接下载？
        this.autoDownload = (typeof window.navigator.msSaveOrOpenBlob === 'function')
    }
};
</script>

<style scoped>
.container {
    position: absolute;
    /* 使其悬浮 */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    /* 背景色半透明，便于显示背景图 */
    background: rgba(255, 255, 255, 0.7);
    /* border-radius: 8px; */
    /* 确保其在背景图上方 */
    z-index: 1;
}

img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    /* 确保背景图位于 .container 后面 */
    z-index: 0;
    pointer-events: none;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    /* 蓝色标题 */
    color: #4f8ad7;
    font-weight: bold;
    margin-bottom: 20px;
}

.sub-header span {
    font-size: 14px;
    /* 使用蓝色与主色调一致 */
    color: #4f8ad7;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
}

.overview {
    margin-bottom: 30px;
}

.overview-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 0.1875rem 0.4375rem rgba(140, 178, 214, 0.2);
    border: 0.0625rem solid #1095e2;
    border-radius: 0.625rem;
    background: linear-gradient(180deg, #fff 2%, #fefefe 60%, #f3f3f7 95%, #fff 100%);
    padding: 0.525rem 0.5rem;
}

.overview h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 30px;
    color: #4f8ad7;
}

.detailed-overview {
    margin-bottom: 10px;
    margin-top: 30px;
}

.detailed-overview h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 30px;
    color: #4f8ad7;
}

.overview-stats {
    display: flex;
    /* 使得每个统计项均匀分布 */
    justify-content: space-evenly;
    /* 统计项之间的间距 */
    gap: 20px;
    /* 增加顶部间距 */
    margin-top: 10px;
}

.stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* flex: 1; */
    text-align: center;
}

.label {
    font-size: 14px;
    /* 蓝色的标签 */
    color: #4f8ad7;
    margin-bottom: 5px;
}

.amount {
    font-size: 18px;
    font-weight: bold;
    color: #070101;
}

.tabs {
    /* display: flex; */
    text-align: center;
    gap: 20px;
    /* margin-bottom: 20px; */
}

.tabs button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    /* 蓝色边框 */
    border: 1px solid #4f8ad7;
    background-color: #eef2f3;
    /* 按钮文字也为蓝色 */
    color: #4f8ad7;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.tabs button.active {
    background-color: #4f8ad7;
    color: #ffffff;
}

.tab-content {
    display: flex;
    flex-direction: column;
}

.tab2-content {
    padding: 20px;
}

.image-section {
    text-align: center;
    margin-bottom: 20px;
}

.text-section {
    margin-bottom: 20px;
    line-height: 1.2;
    text-align: center;
}

.text-section p {
    font-size: 16px;
    color: #333;
}

.button-section {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.tab2-button {
    padding: 10px 10px;
    font-size: 14px;
    cursor: pointer;
    border: 0px solid #557af2;
    background-color: #4f8ad7;
    color: white;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.tab2-button:hover {
    background-color: #3a76c3;
    /* 按钮悬停效果 */
}

.additional-stats-content {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.background-canvas {
    width: 100%;
    height: auto;
    background-color: #eef2f3;
    /* 清新的背景色 */
}

.image-with-qrcode {
    display: inline-block;
    position: relative;
}

/* 手机屏幕（宽度小于768px） */
@media (max-width: 768px) {
    .header {
        font-size: 22px;
        /* 再次缩小字体 */
    }

    .container {
        padding: 10px;
        /* 减小内边距 */
    }

    .overview-item {
        flex-direction: column;
        /* 改为纵向排列 */
        gap: 10px;
        /* 减小间距 */
    }

    .stat {
        font-size: 14px;
        /* 缩小字体 */
    }

    .tabs button {
        font-size: 14px;
        /* 缩小字体 */
        padding: 8px 12px;
        /* 调整内边距 */
    }

    .tab2-content {
        padding: 10px;
        /* 缩小内边距 */
    }

    .image-section {
        margin-bottom: 15px;
        /* 缩小间距 */
    }
}

/* 超小屏幕（宽度小于480px） */
@media (max-width: 480px) {
    .header {
        font-size: 20px;
        /* 再次缩小字体 */
    }

    .stat .amount {
        font-size: 16px;
        /* 缩小数字字体 */
    }

    .overview h3,
    .detailed-overview h3 {
        font-size: 16px;
        /* 缩小标题字体 */
    }
}
</style>
