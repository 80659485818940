<template>
<div class="floating-bubble" :class="{ dragging: dragging }" :style="{ top: `${position.y}px`, left: `${position.x}px` }" @mousedown="startDrag" @touchstart="startDrag" @click="onClick">
    <slot>
        <div class="bubble-content">+</div>
    </slot>
</div>
</template>

<script>
export default {
    data() {
        return {
            position: {
                x: 50,
                y: 50,
            }, // 初始位置
            dragging: false, // 是否正在拖动
            hasMoved: false, // 是否发生过移动
            offset: {
                x: 0,
                y: 0,
            }, // 鼠标相对气泡的偏移量

        };
    },
    methods: {
        startDrag(event) {
    const isTouch = event.type === "touchstart";
    const clientX = isTouch ? event.touches[0].clientX : event.clientX;
    const clientY = isTouch ? event.touches[0].clientY : event.clientY;

    this.offset = {
        x: clientX - this.position.x,
        y: clientY - this.position.y,
    };

    this.dragging = true;
    this.hasMoved = false; // 重置为 false，防止点击时被认为已移动

    // 阻止默认的滑动行为（仅在拖动时）
    const moveHandler = (e) => {
        const moveX = isTouch ? e.touches[0].clientX : e.clientX;
        const moveY = isTouch ? e.touches[0].clientY : e.clientY;

        // 如果移动距离超过一定阈值，认为是拖动
        if (
            Math.abs(moveX - clientX) > 5 ||
            Math.abs(moveY - clientY) > 5
        ) {
            this.hasMoved = true; // 标记已发生拖动
            this.onDrag(e, isTouch);
        }

        // 仅在拖动时阻止默认行为
        if (isTouch) {
            e.preventDefault(); // 阻止滑动行为
        }
    };

    const endHandler = () => this.stopDrag(moveHandler, endHandler);

    // 添加事件监听器时，设置 passive: false，确保 preventDefault 生效
    document.addEventListener(isTouch ? "touchmove" : "mousemove", moveHandler, { passive: false });
    document.addEventListener(isTouch ? "touchend" : "mouseup", endHandler);

    console.log("Start dragging", this.position); // 调试输出
},

onDrag(event, isTouch) {
    if (!this.dragging) return;
    const clientX = isTouch ? event.touches[0].clientX : event.clientX;
    const clientY = isTouch ? event.touches[0].clientY : event.clientY;

    this.position = {
        x: clientX - this.offset.x,
        y: clientY - this.offset.y,
    };

    console.log("Dragging at position:", this.position); // 调试输出
},
        stopDrag(moveHandler, endHandler) {
            this.dragging = false;
            document.removeEventListener("mousemove", moveHandler);
            document.removeEventListener("mouseup", endHandler);
            document.removeEventListener("touchmove", moveHandler);
            document.removeEventListener("touchend", endHandler);

            console.log("Stopped dragging", this.position); // 调试输出

            // 拖动结束后重置 hasMoved 为 false，允许下一次点击
            // setTimeout(() => {
            //     this.hasMoved = false;
            // }, 1000);
            this.hasMoved = false;

            console.log("hasMoved ======= ", this.hasMoved);
        },
        onClick(event) {
            // 只有在未发生移动的情况下触发点击事件
            if (!this.hasMoved) {
                this.$emit("click");
                console.log("Click event triggered"); // 调试输出
            }
        },
    },
};
</script>

<style scoped>
.floating-bubble {
    position: fixed;
    width: 35px;
    height: 35px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s, transform 0.2s;
    pointer-events: auto;
    /* 确保气泡可以接收到所有点击事件 */
    z-index: 999999;
    /* 确保气泡浮动在上层 */
}

.floating-bubble:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.floating-bubble.dragging {
    background-color: #00b59d;
    transform: scale(1.1);
    /* 拖动时气泡放大 */
}

.bubble-content {
    color: white;
    font-size: 24px;
    user-select: none;
}
</style>
