<template>
  <div style="background-color: #eef2fc; height: 100vh">
    <van-nav-bar
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #edf2fd;
      "
      title="我的"
      left-arrow
      @click-left="$router.back()"
    />
    <div style="height: 46px"></div>
    <div class="" style="display: flex; margin: 0.625rem">
      <van-image
        round
        width="1.875rem"
        height="1.875rem"
        :src="$store.state.userInfo.avatar ? $store.state.userInfo.avatar : '/static/image/imageAvatar02@3x.png'"
      />
      <div
        style="
          font-size: 0.375rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <span
          v-if="$store.state.token"
          style="margin-left: 0.3125rem; font-size: 0.5rem"
          >{{ $store.state.userInfo.username }}</span
        >
        <span
          v-else
          style="margin-left: 0.3125rem; font-size: 0.5rem"
          @click="$parent.goNav('/login')"
          >登陆</span
        >
        <span
          style="color: #c0bfc0; margin-left: 0.3125rem; margin-top: 0.3125rem"
          >永久地址:{{ $store.state.appInfo.h5_url }}</span
        >
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 0.1875rem 0.4375rem rgba(140, 178, 214, 0.2);
        margin: 0.625rem;
        border: 0.0625rem solid #fff;
        border-radius: 0.625rem;
        background: linear-gradient(
          180deg,
          #fff 2%,
          #fefefe 60%,
          #f3f3f7 95%,
          #fff 100%
        );
        padding: 0.625rem 0.5rem;
      "
    >
      <div style="display: flex; flex-direction: column">
        <div style="font-size: 0.375rem">
          中心钱包
          <van-icon name="eye-o" size="0.4rem" />
          <!-- <van-icon name="closed-eye" size="0.4rem" /> -->
        </div>
        <div style="font-size: 0.5rem; margin-top: 0.25rem">
          ${{
            $store.state.userInfo.balance
              ? $store.state.userInfo.balance
              : "0.00"
          }}<van-icon name="replay" size="0.4rem" />
        </div>
      </div>
      <div style="display: flex">
        <div style="text-align: center" @click="$parent.goNav('/recharge')">
          <van-image
            round
            width=".9375rem"
            height=".9375rem"
            src="static/image/withdrawal.6fc5b140031dfbd3b77cc6aafbf4fe47.png"
          />
          <div style="font-size: 0.375rem; margin-top: 0.125rem">存款</div>
        </div>
        <div
          style="text-align: center; margin: 0 0.5rem"
          @click="$parent.goNav('/transfer')"
        >
          <van-image
            round
            width=".9375rem"
            height=".9375rem"
            src="static/image/transfer.cada0b68f36cabf40a9956d59bb9b1fe.png"
          />
          <div style="font-size: 0.375rem; margin-top: 0.125rem">转账</div>
        </div>
        <div style="text-align: center" @click="$parent.goNav('/withdrawal')">
          <van-image
            round
            width=".9375rem"
            height=".9375rem"
            src="static/image/deposit.0947ecf747f936e9f9a20e20fe3a5806.png"
          />
          <div style="font-size: 0.375rem; margin-top: 0.125rem">取款</div>
        </div>
      </div>
    </div>
    <div
      style="
        margin: 0.625rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        place-items: center;
        grid-gap: 0.3125rem;
        border-radius: 0.625rem;
        background-color: #fff;
        box-shadow: 0 0.1875rem 0.4375rem rgba(140, 178, 214, 0.2);
        box-sizing: border-box;
        padding: 0.625rem;
      "
    >
      <div style="text-align: center" @click="$parent.goNav('/money')">
        <van-image
          round
          width=".9375rem"
          height=".9375rem"
          src="static/image/0.fa44efc644589909c1e16f3dedb07626.png"
        />
        <div style="font-size: 0.375rem">我的钱包</div>
      </div>
      <div style="text-align: center" @click="$parent.goNav('/transRecord')">
        <van-image
          round
          width=".9375rem"
          height=".9375rem"
          src="static/image/1.404d07d89fe207dd01ddbbe812b97c01.png"
        />
        <div style="font-size: 0.375rem">交易记录</div>
      </div>
      <div style="text-align: center" @click="$parent.goNav('/betRecord')">
        <van-image
          round
          width=".9375rem"
          height=".9375rem"
          src="static/image/2.c21324096c848ea53d629c3f8fc20631.png"
        />
        <div style="font-size: 0.375rem">投注记录</div>
      </div>
      <div style="text-align: center" @click="$parent.goNav('/fanshui')">
        <van-image
          round
          width=".9375rem"
          height=".9375rem"
          src="static/image/3.983decfccd2bdc503c4c42fdf583de89.png"
        />
        <div style="font-size: 0.375rem">返水记录</div>
      </div>
      <div style="text-align: center" @click="$parent.goNav('/activityRecord')">
        <van-image
          round
          width=".9375rem"
          height=".9375rem"
          src="static/image/2.c21324096c848ea53d629c3f8fc20631.png"
        />
        <div style="font-size: 0.375rem">活动记录</div>
      </div>
      <div v-if="$store.state.userInfo.isagent==1"   style="text-align: center" @click="$parent.goNav('/')">
        <van-image
          round
          width=".9375rem"
          height=".9375rem"
          src="static/image/6.d62837c7256bdcb8be821f3ad2972ea2.png"
        />
        <div style="font-size: 0.375rem">合营中心</div>
      </div> 
      <div v-else  style="text-align: center" @click="$parent.goNav('/activityInfo?id=5')">
        <van-image
          round
          width=".9375rem"
          height=".9375rem"
          src="static/image/6.d62837c7256bdcb8be821f3ad2972ea2.png"
        />
        <div style="font-size: 0.375rem">合营中心</div>
      </div> 
    </div>
    <van-cell-group inset style="margin: 0.625rem">
      <van-cell
        is-link
        :border="false"
        center
        title="个人资料"
        icon="static/image/4.d40de41e77eefd350e82d9a3534b25c3.png"
        @click="$parent.goNav('/userinfo')"
      />
      <van-cell
        is-link
        :border="false"
        center
        title="消息中心"
        icon="static/image/5.c6da83c12132a221cff965aefc959904.png"
        @click="$parent.goNav('/message')"
      />
      <van-cell
        is-link
        :border="false"
        center
        title="福利中心"
        icon="static/image/6.d62837c7256bdcb8be821f3ad2972ea2.png"
        @click="$parent.goNav('/welfare')"
      />
      <van-cell
        is-link
        :border="false"
        center
        title="我的会员"
        icon="static/image/6.d62837c7256bdcb8be821f3ad2972ea2.png"
        @click="$parent.goNav('/vip')"
      />
      <van-cell
        is-link
        :border="false"
        center
        title="意见反馈"
        icon="static/image/7.31525e63cb61f29775d42bb0d42d2027.png"
        @click="$parent.goNav('/kefu')"
      />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'gamePage',
  data() {
    return {
      url: null,
      type: null,
    };
  },
  created() {
    let that = this;
    var query = that.$route.query;
    // 打开代理
    console.log(this.$store);
  }
};
</script>

<style scoped></style>
