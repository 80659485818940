<template>
  <div style="position: absolute;width: 100%;bottom: 0;">
    <!-- 1111 -->
    <van-tabbar v-model="active" @change="onChange" >
      <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
      <!-- <van-tabbar-item icon="play-circle-o" to="kp">看片</van-tabbar-item> -->
      <van-tabbar-item icon="service-o" to="kefu">客服</van-tabbar-item>
      <van-tabbar-item icon="balance-pay" to="activity">优惠</van-tabbar-item>
      <van-tabbar-item icon="user-o" to="/user" >我的</van-tabbar-item>
    </van-tabbar>
  </div>

  
</template>

<script>
export default {
  data() {
    return {
      active: 0, // 默认选中第一个 Tab
      // routes: ['/', '/kp', '/kefu', '/activity', '/user'], // Tab 对应的路由
      routes: ['/', '/kefu', '/activity', '/user'], // Tab 对应的路由
    };
  },
  watch: {
    // 监听路由变化
    $route(to) {
      this.updateActive(to.path);
    },
  },
  created() {
    // 初始化时同步 active
    this.updateActive(this.$route.path);
  },
  methods: {

    updateActive(path) {
      const index = this.routes.indexOf(path); // 获取当前路径对应的索引
      this.active = index !== -1 ? index : 0;  // 如果匹配不到，则默认选中第一个
    },
    onChange(index) {
      this.$router.push(this.routes[index]).catch((err) => {
      // Handle the error if navigation is cancelled
      }); // 跳转到对应路径
    },
  },
};
</script>
