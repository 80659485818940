import { render, staticRenderFns } from "./addTopay.vue?vue&type=template&id=95b6a984&scoped=true"
import script from "./addTopay.vue?vue&type=script&lang=js"
export * from "./addTopay.vue?vue&type=script&lang=js"
import style0 from "./addTopay.vue?vue&type=style&index=0&id=95b6a984&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95b6a984",
  null
  
)

export default component.exports