<template>
    <div class="sdg sdgg stddss" style="width: 100%; min-height: 100vh; background-color: #f1f1f1; padding-bottom: 50px">
      <!-- 导航栏 -->
      <van-nav-bar
        style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd; z-index: 222"
        title="PG电子"
        left-arrow
        @click-left="$router.back()"
      />
      <div style="height: 50px"></div>
  
      <!-- 搜索框 -->
      <div style="background: #fff; box-sizing: border-box; padding: 10px;">
        <div class="nav-input-container">
          <van-icon name="search" class="search-icon" />
          <input
            v-model="searchQuery"
            class="nav-input"
            type="text"
            placeholder="请输入游戏名称"
          />
        </div>
      </div>
  
      <!-- 游戏列表 -->
      <div class="gameBoxs">
        <div class="rigts">
            <div  v-for="(item, index) in filteredItems"
                :key="index"
                class="game-item"
                @click="pgGameClick(item)"
                >
                <!-- 游戏图标 -->
                <img :src="item.img" alt="Game Icon" class="game-icon" />
                <!-- 游戏名称 -->
                <p class="game-name">{{ item.name }}</p>
                </div>
        </div>
      </div>
  
      <!-- 状态提示 -->
      <p v-if="isLoading"  class="tishime" style="text-align: center;">加载中...</p>
      <p v-if="allLoaded" class="tishime" style="text-align: center;">没有更多了~</p>
  
      <!-- 加载更多 -->
      <button v-if="!isLoading && !allLoaded" @click="loadMore">加载更多</button>
    </div>
  </template>
  <script>
  import { NavBar, Icon } from "vant";
  
  export default {
    components: {
      "van-nav-bar": NavBar,
      "van-icon": Icon,
    },
    data() {
      return {
        newItems: [], // 已加载的游戏数据
        currentPage: 1, // 当前页码
        pageSize: 120, // 每页加载条数
        isLoading: false, // 是否正在加载
        allLoaded: false, // 是否加载完成
        searchQuery: "", // 搜索关键字
        debounceTimer: null, // 用来保存定时器 ID
      };
    },
    computed: {
      // 通过搜索关键字过滤游戏列表
      filteredItems() {
        return this.newItems.filter((item) =>
          item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      },
    },
    mounted() {
      // 初次加载数据
      this.loadMore();
    },
    methods: {
      //pgGameClick
      pgGameClick(item){
         // 清除之前的定时器
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }
      
        // 设置新的定时器
        this.debounceTimer = setTimeout(() => {
          this.$parent.openPgGamePage(item.pgcode, false);
        }, 300); // 设置防抖延迟时间
      },

      // 加载数据
      loadMore() {
        if (this.isLoading || this.allLoaded) return;
  
        this.isLoading = true;
  
        // 调用接口
        this.$apiFun.post("/api/pggamelist", { page: this.currentPage, size: this.pageSize })
          .then((res) => {
            if (res.code !== 200) {
              this.showToast(res.message);
              return;
            }
  
            // 追加新数据到列表
            const newItems = res.data.list || [];
            this.newItems = [...this.newItems, ...newItems];

            // 判断是否加载完成
            if (newItems.length < this.pageSize) {
              this.allLoaded = true;
            }
  
            this.currentPage += 1;
          })
          .catch((err) => {
            console.error("数据加载失败", err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .nav-input-container {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 20px;
    padding: 5px 10px;
    height: 32px;
    width: 100%;
  }
  
  /* 输入框样式 */
  .nav-input {
    border: none;
    outline: none;
    background: transparent;
    flex: 1;
    padding-left: 8px;
    font-size: 14px;
  }
  
  /* 搜索图标样式 */
  .search-icon {
    color: #888;
  }
  .tishime{
    color: gray;
    font-size: 12px;
  }
  /* 游戏列表样式 */
  .gameBoxs {
  background: #edf1ff;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  

  .rigts {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .game-item {
      width: 48%; /* 每行两个元素 */
      margin-top: 10px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      padding: 10px;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease;
      }

      .game-icon {
        width: 100%;
        border-radius: 10px;
      }

      .game-name {
        margin-top: 8px;
        font-size: 14px;
        color: #333;
      }
    }
  }
}
  </style>  