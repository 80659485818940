import { render, staticRenderFns } from "./pgme.vue?vue&type=template&id=61c20820&scoped=true"
import script from "./pgme.vue?vue&type=script&lang=js"
export * from "./pgme.vue?vue&type=script&lang=js"
import style0 from "./pgme.vue?vue&type=style&index=0&id=61c20820&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c20820",
  null
  
)

export default component.exports